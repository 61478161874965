import { OktaAuth } from '@okta/okta-auth-js';
import axios from 'axios';
import { getOktaToken, getUsername } from './okta-token-manager';
import { AuthPermissions } from './types';

export const oktaAuthConfig = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER_LINK,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  responseType: 'code',
  tokenManager: {
    expireEarlySeconds: 120,
    autoRenew: true,
    storageKey: 'dct-okta-token',
  },
});

export const logout = (oktaAuthConfig: any) => {
  oktaAuthConfig.signOut({
    postLogoutRedirectUri: `${window.location.origin}/logout/callback`,
  });
};

export const setEventOnExpired = (oktaAuthConfig: any) => {
  oktaAuthConfig.tokenManager.off('expired');
  // Triggered when a token has expired
  oktaAuthConfig.tokenManager.on(
    'expired',
    function (key: any, expiredToken: any) {
      oktaAuthConfig.tokenManager.renew('accessToken');
      oktaAuthConfig.tokenManager.renew('idToken');
    },
  );
};

export const fetchPermission = async (): Promise<
  { permissions: AuthPermissions []; userName: any } | null | undefined
> => {
  let permissions: AuthPermissions[] = [];
  const userName: string = getUsername();
  const oktaToken = getOktaToken();
  const graphQLQuery = `query dctAuthorization($username: String!, $workstreams: [String!], $skipWorkstreamLevelAuth: Boolean) {\n dctAuthorization(     username: $username     workstreams: $workstreams     skipWorkstreamLevelAuth: $skipWorkstreamLevelAuth   ) { mct mctAuthorization{aoh anc abr ari aml adl asm ato aohAuthorization{shopFloor wct eTOC} ancAuthorization{shopFloor wct eTOC} abrAuthorization{shopFloor wct eTOC} ariAuthorization{shopFloor wct eTOC} amlAuthorization{shopFloor wct eTOC} adlAuthorization{shopFloor wct eTOC} asmAuthorization{shopFloor wct eTOC} atoAuthorization{shopFloor wct eTOC}  __typename}  } }`
  const variable = {"username":userName,"workstreams":["mct"],"skipWorkstreamLevelAuth":false}
  const payload = { query: graphQLQuery, variables: variable };
  
  try {
    const res = await axios({
      method: 'post',
      url: process.env.REACT_APP_AUTH_LINK,
      data: payload,
      headers: {
        Authorization: `Bearer ${oktaToken}`,
      },
    });
    if (res.status === 200 && res.data) {
      const permData = res.data.data.dctAuthorization;
      const {
        mctAuthorization: {
          aoh,
          anc,
          abr,
          ari,
          aml,
          adl,
          asm,
          ato,
          aohAuthorization,
          ancAuthorization,
          abrAuthorization,
          ariAuthorization,
          amlAuthorization,
          adlAuthorization,
          asmAuthorization,
          atoAuthorization
        }
      } = permData;
      permissions = [
        {
          url: 'home',
          label: 'Home',
          isAuthorized: true
        },
        {
          url: 'manufacturing',
          label: 'Manufacturing',
          isAuthorized: true,
          workstreams: {
            mct: [
              {
                url: 'aoh',
                label:'AOH',
                isAuthorized: aoh,
                personas: aohAuthorization
              },
              {
                url: 'abr',
                label: 'ABR',
                isAuthorized: abr,
                personas: abrAuthorization
              }, 
              {
                url: 'anc',
                label: 'ANC',
                isAuthorized: anc,
                personas: ancAuthorization
              },
              {
                url: 'ari',
                label: 'ARI',
                isAuthorized: ari,
                personas: ariAuthorization
              },
              {
                url: 'aml',
                label: 'AML',
                isAuthorized: aml,
                personas: amlAuthorization
              },
              {
                url: 'adl',
                label: 'ADL',
                isAuthorized: adl,
                personas: adlAuthorization
              },
              {
                url: 'asm',
                label: 'ASM',
                isAuthorized: asm,
                personas: asmAuthorization
              },
              {
                url: 'ato',
                label: 'ATO',
                isAuthorized: ato,
                personas: atoAuthorization
              }
            ]
          }
        }
      ];
      return { permissions, userName };
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
