import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

//OKTA
import { LoginCallback, useOktaAuth } from '@okta/okta-react';

// Custom Okta
import { RequiredAuth } from './secure-route';
import {
  oktaAuthConfig,
  setEventOnExpired,
  fetchPermission,
} from './okta-authentication-manager';
import Logout from './logout';
import { AuthPermissions } from './types';

type PropType = {
  setApplicationPermissions: (
    authSuccessful: boolean,
    permissions: any,
    userName: string,
  ) => void;
};

const AuthRoutes: React.FC<PropType> = props => {
  const { setApplicationPermissions } = props;
  const { oktaAuth, authState } = useOktaAuth();
  const [initialPermissions, setInitialPermissions] =
    useState<AuthPermissions[] | null>(null);

  /********** RENEW TOKEN EVERY 15 MINUTES  *********/
  useEffect(() => {
    const renewTokenInterval = setInterval(() => {
      oktaAuthConfig.tokenManager.renew('accessToken');
      oktaAuthConfig.tokenManager.renew('idToken');
    }, 900000); // Every 15min

    return () => clearInterval(renewTokenInterval);
  }, []);

  /********** AUTOMATICALLY RENEW TOKEN ON EXPIRATION *********/
  useEffect(() => {
    setEventOnExpired(oktaAuthConfig);

    return () => oktaAuthConfig.tokenManager.off('expired');
  }, []);

  /********** OBTAIN AUTHORIZATION *********/
  useEffect(() => {
    const initialPermissionFetch = async () => {
      const response = await fetchPermission();

      if (response?.permissions && response.userName) {
        setApplicationPermissions(
          true,
          response.permissions,
          response.userName,
        );
        setInitialPermissions([ ...response.permissions ]);
      } else {
        setApplicationPermissions(false, {}, 'USER_NOT_FOUND');
      }
    };

    const token = oktaAuth.getAccessToken();
    if (token && authState && !initialPermissions) {
      initialPermissionFetch();
    }
  }, [oktaAuth, authState, initialPermissions, setApplicationPermissions]);

  return (
    <Routes>
      <Route path="*" element={<RequiredAuth />}>
        <Route path="" element={<></>} />
      </Route>
      <Route path="/implicit/callback" element={<LoginCallback />} />
      <Route path="/auth/logout" element={<Logout />} />
    </Routes>
  );
};

export default AuthRoutes;
