import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Loader } from '@gitlab-rtsensing/component-library';
import { AnimatePresence } from 'framer-motion';

import './index.scss';
import RequestAccessModal from '../request-access-modal';

interface TransitionWaterfallProps {
  transitionActive: boolean;
  transitionError: boolean;
}

type AdminMessage = {
  action: 'access request successful';
};

/**
 * Animated transition which will render the component in, and then will animate the component
 * out once the parent stops the transition
 * @param {function} transitionActive - Controller set by the parent to mount/unmount the
 * transition and trigger entry/exit animations
 */
const TransitionWaterfall = (props: TransitionWaterfallProps) => {
  const [requestAccessModalOpen, setRequestAccessModalOpen] = useState(false);
  const { transitionActive, transitionError } = props;
  const contentRoot =
    document.getElementsByTagName('body')[0] ?? ({} as Element);

  const [accessRequestSuccessful, setAccessRequestSuccessful] = useState(false);

  function handleRequestAccessClick() {
    setRequestAccessModalOpen(true);

    const abortController = new AbortController();
    window.addEventListener(
      'message',
      (event: MessageEvent<AdminMessage>) => {
        if (
          event.origin !== window.location.origin ||
          event.data.action !== 'access request successful'
        ) {
          return;
        }
        abortController.abort();
        setAccessRequestSuccessful(true);
      },
      { signal: abortController.signal },
    );
  }

  return createPortal(
    <>
      <AnimatePresence>
        {transitionActive && (
          <div className='dct-auth-loader'>
            <Loader status='loading' />
          </div>
        )}
      </AnimatePresence>
      {requestAccessModalOpen && (
        <RequestAccessModal
          opened={requestAccessModalOpen}
          onRequestAccessModalClosed={() => setRequestAccessModalOpen(false)}
          accessRequestSuccessful={accessRequestSuccessful}
        />
      )}
    </>,
    contentRoot,
  );

};

export default TransitionWaterfall;
